import { Space } from 'antd';
import moment from 'moment';
import logo from '../../../assets/img/logo.png';

const formatAddress = (address, complement) => `${address + (complement ? ' - ' + complement : '')}`

const ProposalTerms = (props) => {
  const { proposal } = props;
  return (
    <>
      <Space className='proposal-terms-space' direction='vertical'>
        <img src={logo} alt='Logo Tupi Mobilidade' className='footer-logo' />
        <br></br>
        <br></br>
        <h1>Proposta Comercial</h1>
        <span><strong>Data de emissão:</strong> {moment(proposal.createdAt).format('DD/MM/YYYY')}</span>
        <span><strong>Data de aceite:</strong> {moment().format('DD/MM/YYYY')}</span>
        <br></br>
        <h2>Dados da Empresa Cliente</h2>
        <ul>
          <li><strong>Razão Social:</strong> {proposal.company.name}</li>
          <li><strong>CNPJ:</strong> {proposal.company.cnpj}</li>
          {proposal.company.municipalRegistrationNumber && (<li><strong>Inscrição Municipal:</strong> {proposal.company.municipalRegistrationNumber}</li>)}
          {proposal.company.stateRegistrationNumber && (<li><strong>Inscrição Estadual:</strong> {proposal.company.stateRegistrationNumber}</li>)}
          <li><strong>Endereço:</strong> {formatAddress(proposal.company.address, proposal.company.addressComplement)}</li>
        </ul>
        <h2>Dados do Cliente</h2>
        <ul>
          <li><strong>Nome:</strong> {proposal.client.name}</li>
          <li><strong>CPF:</strong> {proposal.client.cpf}</li>
          <li><strong>E-mail:</strong> {proposal.client.email}</li>
        </ul>
        <h2>Detalhes da Proposta</h2>
        <h3>1. Carregadores</h3>
        <table border="1" cellPadding="8" cellSpacing="0" style={{width: '100%', borderCollapse: 'collapse', margin: '10px 0', fontSize: '14px'}}>
          <thead>
            <tr>
              <th>Quantidade</th>
              <th>Marcas</th>
              <th>Valor Mensal por Carregador</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{proposal.chargers.quantity}</td>
              <td>{proposal.chargers.brands.join(', ')}</td>
              <td>R$ {proposal.planId.price.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
        <h3>2. Planos e Condições Comerciais</h3>
        <ul>
          <li><strong>Plano Escolhido:</strong> {proposal.planId.name}</li>
          <li><strong>Valor Total Mensal:</strong> {`R$ ${(proposal.chargers.quantity * proposal.planId.price).toFixed(2)}`}</li>
          {(proposal.tupiRate > 0) && (<li><strong>Porcentagem de Repasse:</strong> {`Cliente: ${(100 - proposal.tupiRate).toFixed(2)}%, Tupi ${(proposal.tupiRate).toFixed(2)}%`}</li>)}
        </ul>
        <h3>3. Detalhes do plano</h3>
        <ul>
          {proposal.planId.features.map((feat) => (
            <li>{`${feat}`}</li>
          ))}
        </ul>



        <h2>Prazo e Validade da Proposta</h2>
        <p>Esta proposta é válida até {moment(proposal.expirationDate).format('DD/MM/YYYY')}.</p>
        <h2>Observações</h2>
        <p>As condições de pagamento e outros detalhes contratuais serão discutidos e acordados em contrato. Esta proposta visa autorizar o processo de conexão e configuração de seus carregadores enquanto estamos preparando o contrato.</p>
        <p>Esta proposta comercial é apresentada como uma oferta preliminar e informativa, não constituindo um contrato ou obrigação definitiva entre as partes. As condições aqui descritas, incluindo preços, prazos, e especificações técnicas, são indicativas e estão sujeitas a ajustes. A formalização de qualquer acordo comercial, incluindo todas as condições e obrigações detalhadas, ocorrerá exclusivamente por meio de um contrato formal, a ser elaborado e assinado por ambas as partes após as negociações finais.</p>
      </Space>
    </>
  );
};

export default ProposalTerms;

export const CHARGER_INFO_TITLE = {
  pt: "Dados do carregador",
  es: "Información del cargador",
  en: "Charger Information",
};

export const CHARGER_BRAND_LABEL = {
  pt: "Marca do carregador",
  es: "Marca del cargador",
  en: "Charger Brand",
};

export const CHARGER_QUANTITY_LABEL = {
  pt: "Quantidade de carregadores",
  es: "Cantidad de cargadores",
  en: "Charger Quantity",
};

export const CHARGER_BRAND_PLACEHOLDER = {
  pt: "Selecione as marcas dos carregadores",
  es: "Seleccione las marcas de los cargadores",
  en: "Select the charger brands",
};

export const CHARGER_QUANTITY_PLACEHOLDER = {
  pt: "Insira a quantidade de carregadores",
  es: "Ingrese la cantidad de cargadores",
  en: "Enter the charger quantity",
};

export const INVALID_CHARGER_BRAND = {
  pt: "Selecione pelo menos uma opção",
  es: "Seleccione al menos una opción",
  en: "Select at least one option",
};

export const REQUIRED_FIELD = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Required field",
};
export const EXPIRED_TITLE = {
    pt: "A proposta expirou",
    en: "This proposal has expired",
    es: "La propuesta ha expirado",
  };
  
  export const EXPIRED_CONTENT = {
    pt: "Informamos que a proposta enviada anteriormente está expirada. Se você ainda estiver interessado, entre em contato conosco para obter uma nova proposta atualizada. Estamos à disposição para ajudá-lo com qualquer dúvida ou necessidade.",
    en: "We inform that the previously sent proposal has expired. If you're still interested, contact us to get a new updated proposal. We are available to assist you with any questions or needs.",
    es: "Le informamos que la propuesta enviada anteriormente ha expirado. Si aún está interesado, contáctenos para obtener una nueva propuesta actualizada. Estamos a su disposición para ayudarle con cualquier duda o necesidad.",
  };
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Layout, Typography, Space, Button } from 'antd';
import api from '../../services/api';
import CustomFooter from '../../components/Footer';
import CustomHeader from '../../components/Header';
import successImage from '../../assets/img/success.png';
import * as texts from './locales';
import './styles.css';
import { PROPOSAL_STATUS } from '../../constants/proposal';

const { Content } = Layout;
const { Text } = Typography;

const contentStyle = {
  textAlign: 'left',
  padding: '16px 93px 16px 93px',
  backgroundColor: '#FFFFFF',
};

const Success = () => {
  const [proposal, setProposal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const history = useHistory();
  const lang = 'pt';

  const fetchProposalData = async () => {
    try {
      const response = await api.get(`/proposals/${id}`);
      setProposal(response.data);
      setLoading(false);
    } catch (err) {
      setError('Proposta não encontrada');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProposalData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (proposal.status === PROPOSAL_STATUS.EXPIRED) {
    history.push({
      pathname: `/proposals/${id}/expired`
    });
  }

  return (
    <div className='success-page'>
      <Layout>
        <CustomHeader />
        <Content className='success-content' style={contentStyle}>
          <img src={successImage} alt='Sucesso' className='success-image' /> 

          <Space className='success-text-space' direction='vertical'>
            <Text className='success-text-title'>{texts.SUCCESS_TITLE[lang]}</Text>
            <Text className='success-text-content'>{texts.SUCCESS_CONTENT_1[lang]}</Text>
            <Text className='success-text-content'>{texts.SUCCESS_CONTENT_2[lang]}</Text>
            <Text className='success-text-content'>{texts.SUCCESS_CONTENT_3[lang]}</Text>
            <a href={proposal.termsUrl} download target="_blank" rel="noreferrer"><Button type="link" className='download-terms'>{texts.DOWNLOAD_TERMS[lang]}</Button></a>
          </Space>
          
        </Content>
        <CustomFooter />
      </Layout>
    </div>
  );
};

export default Success;

import React from 'react';
import { Form, Input, Typography } from 'antd';
import * as texts from './locales';

const { Text } =  Typography;

const CompanyInfo = () => {
  const lang = 'pt';

  return (
    <>
      <Text className='company-info-title'>{texts.COMPANY_INFO_TITLE[lang]}</Text>
      <Form.Item
        className='form-field' 
        label={texts.COMPANY_NAME_LABEL[lang]}
        name="companyName"
        rules={[{ required: true, message: texts.REQUIRED_FIELD[lang] }]}
      >
        <Input 
          placeholder={texts.COMPANY_NAME_PLACEHOLDER[lang]} 
          maxLength={100}
        />
      </Form.Item>

      <Form.Item
        className='form-field' 
        label={texts.CNPJ_LABEL[lang]}
        name="cnpj"
        rules={[
          { 
            required: true, 
            message: texts.INVALID_CNPJ[lang] 
          },
          {
            pattern: /^\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}$/,
            message: texts.INVALID_CNPJ[lang]
          }
        ]}
      >
        <Input 
          placeholder={texts.CNPJ_PLACEHOLDER[lang]} 
          maxLength={18}
        />
      </Form.Item>

      <Form.Item
        className='form-field' 
        label={texts.MUNICIPAL_REGISTRATION_LABEL[lang]}
        name="municipalRegistrationNumber"
        rules={[
          {required: false}, 
          {pattern: /^\d+$/, message: texts.INVALID_NUMBER[lang]}
        ]}
      >
        <Input 
          placeholder={texts.MUNICIPAL_REGISTRATION_PLACEHOLDER[lang]} 
          maxLength={15}
        />
      </Form.Item>

      <Form.Item
        className='form-field' 
        label={texts.STATE_REGISTRATION_LABEL[lang]}
        name="stateRegistrationNumber"
        rules={[
          {required: false}, 
          {pattern: /^\d+$/, message: texts.INVALID_NUMBER[lang]}
        ]}
      >
        <Input 
          placeholder={texts.STATE_REGISTRATION_PLACEHOLDER[lang]} 
          maxLength={15}
        />
      </Form.Item>

      <Form.Item
        className='form-field' 
        label={texts.ZIP_CODE_LABEL[lang]}
        name="zipCode"
        rules={[
          { 
            required: true, 
            message: texts.INVALID_ZIP_CODE[lang] 
          },
          {
            pattern: /^\d{5}-?\d{3}$/,
            message: texts.INVALID_ZIP_CODE[lang]
          }
        ]}
      >
        <Input 
          placeholder={texts.ZIP_CODE_PLACEHOLDER[lang]} 
          maxLength={9}
        />
      </Form.Item>

      <Form.Item
        className='form-field' 
        label={texts.ADDRESS_LABEL[lang]}
        name="address"
        rules={[{ required: true, message: texts.REQUIRED_FIELD[lang] }]}
      >
        <Input 
          placeholder={texts.ADDRESS_PLACEHOLDER[lang]} 
          maxLength={200}
        />
      </Form.Item>

      <Form.Item
        className='form-field' 
        label={texts.ADDRESS_COMPLEMENT_LABEL[lang]}
        name="addressComplement"
        rules={[{ required: false }]}
      >
        <Input 
          placeholder={texts.ADDRESS_COMPLEMENT_PLACEHOLDER[lang]} 
          maxLength={100}
        />
      </Form.Item>
    </>
  );
};

export default CompanyInfo;

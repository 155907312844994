import React from 'react';
import { Form, Input, Typography } from 'antd';
import * as texts from './locales';

const { Text } =  Typography;



const ClientInfo = () => {
  const lang = 'pt';

  return (
    <>
      <Text className='client-info-title'>{texts.PERSONAL_INFO_TITLE[lang]}</Text>
      <Form.Item
        className='form-field'
        label={texts.CLIENT_NAME_LABEL[lang]}
        name="clientName"
        rules={[{ required: true, message: texts.REQUIRED_FIELD[lang] }]}
      >
        <Input 
          placeholder={texts.CLIENT_NAME_PLACEHOLDER[lang]} 
          maxLength={100}  
        />
      </Form.Item>

      <Form.Item
        className='form-field'
        label={texts.EMAIL_LABEL[lang]}
        name="email"
        rules={[{ required: true, message: texts.INVALID_EMAIL[lang], type: 'email' }]}
      >
        <Input 
          placeholder={texts.EMAIL_PLACEHOLDER[lang]} 
          maxLength={254}
        />
      </Form.Item>

      <Form.Item
        className='form-field' 
        label={texts.CPF_LABEL[lang]}
        name="cpf"
        rules={[
          { required: true, message: texts.INVALID_CPF[lang] },
          {
            pattern: /^\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/,
            message: texts.INVALID_CPF[lang],
          },
        ]}
      >
        <Input 
          placeholder={texts.CPF_PLACEHOLDER[lang]} 
          maxLength={14}
        />
      </Form.Item>
    </>
  );
};

export default ClientInfo;

import { Layout } from 'antd';
import logo from '../../assets/img/logo.png';
import './style.css';

const { Header } = Layout;

const CustomHeader = () => {
  return (
    <div className='header'>
      <Header className="custom-header">
        <img src={logo} alt='Logo Tupi Mobilidade'/>
      </Header>
    </div>
  );
};

export default CustomHeader;


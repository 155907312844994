import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Button, Layout, Typography, Divider, Collapse } from 'antd';
import 'antd/dist/reset.css';
import * as texts from './locales';
import api from "../../services/api";
import ClientInfo from './Forms/ClientInfo';
import CompanyInfo from './Forms/CompanyInfo';
import ChargersInfo from './Forms/ChargersInfo';
import CustomStepper from '../../components/Stepper';
import CustomFooter from '../../components/Footer';
import CustomHeader from '../../components/Header';
import "./styles.css";
import { PROPOSAL_STATUS } from '../../constants/proposal';

const { Content, Sider } = Layout;
const { Text } = Typography;

const Proposal = () => {
  const [proposal, setProposal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [listModels, setListModels] = useState([]);
  const [form] = Form.useForm();
  const history = useHistory();
  const { id } = useParams();
  const [plan, setPlan] = useState({
    enabled: true,
    faq: [],
    features: [],
    name: "Extra",
    period: "P1M",
    price: 10,
    takeRate: 0,
  });

  const fetchProposalData = async () => {
    try {
      const response = await api.get(`/proposals/${id}`);
      setProposal(response.data);
      setLoading(false);
      if (response.data) {
        form.setFieldsValue({
          clientName: response.data.client?.name || '',
          email: response.data.client?.email || '',
          cpf: response.data.client?.cpf || '',
          companyName: response.data.company?.name || '',
          cnpj: response.data.company?.cnpj || '',
          municipalRegistrationNumber: response.data.company?.municipalRegistrationNumber || '',
          stateRegistrationNumber: response.data.company?.stateRegistrationNumber || '',
          zipCode: response.data.company?.cep || '',
          address: response.data.company?.address || '',
          addressComplement: response.data.company?.addressComplement || '',
          chargerQuantity: response.data.chargers?.quantity || '',
          chargerBrands: response.data.chargers?.brands || null,
        });
        setPlan(response.data.planId);
      }
    } catch (err) {
      setError('Proposta não encontrada');
      setLoading(false);
    }
  };

  const fetchStationModels = async () => {
    try {
      const response = await api.get(`station-models`);
      setListModels(response.data);
    } catch (err) {
      console.error('Failed to fetch station models', err);
    }
  };

  useEffect(() => {
    fetchProposalData();
    fetchStationModels();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values) => {
    try {
      const update = {
        client: {
          name: values.clientName,
          email: values.email,
          cpf: values.cpf,
        },
        company: {
          name: values.companyName,
          cnpj: values.cnpj,
          municipalRegistrationNumber: values.municipalRegistrationNumber,
          stateRegistrationNumber: values.stateRegistrationNumber,
          cep: values.zipCode,
          address: values.address,
          addressComplement: values.addressComplement,
        },
        chargers: {
          quantity: values.chargerQuantity,
          brands: values.chargerBrands,
        },
      };

      const updatedProposalResponse = await api.post(`/proposals/${id}/fill`, update);

      history.push({
        pathname: `/proposals/${id}/confirmation`,
        state: { proposal: updatedProposalResponse.data },
      });
    } catch (error) {
      console.error('Falha ao atualizar a proposta', error);
    }
  };

  const getFaqItems = () => {
    return plan.faq?.map((qa, index) => { 
      return {
        key: index + 1,
        label: qa.question,
        children: <p>{qa.answer}</p>,
      }
    })
  }

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (proposal.status === PROPOSAL_STATUS.ACCEPTED) {
    history.push({
      pathname: `/proposals/${id}/success`,
      state: {proposal}
    });
  }

  if (proposal.status === PROPOSAL_STATUS.EXPIRED) {
    history.push({
      pathname: `/proposals/${id}/expired`
    });
  }

  return (
    <Layout>
      <CustomHeader />
      <Layout>
        <Content className='proposal'>
          <CustomStepper current_step={0} />
          <Text className='forms-title'>{texts.FORMS_TITLE['pt']}</Text>
          <Form 
            className='forms'
            onFinish={handleSubmit}
            layout='vertical'
            form={form}
            scrollToFirstError
          >
            <ClientInfo />
            <CompanyInfo />
            <ChargersInfo listModels={listModels}/>
            <Form.Item>
              <Button className='button' type="primary" htmlType="submit">
                Continuar
              </Button>
            </Form.Item>
          </Form>
        </Content>
        <Sider className='plan-details' width='40%'>
          <h2>Resumo do plano</h2>
          <h1 className='plan-name'>{plan.name}</h1>
          <div className='plan-price'>R$ {plan.price} /mês</div>
          <Divider />
          Recursos principais incluem:
          <ul>
            {plan?.features?.map((feature, index) => <li key={index}>{feature}</li>)}
          </ul>
          {
            proposal.tupiRate 
              ? <div className='plan-tupi-rate-warning'>
                  Para operações de cobrança, aplicamos uma taxa de transação de {proposal.tupiRate}% por recarga.
                </div> 
              : <div className='no-tupi-rate-space' />
          }
          <h1>Sobre este plano</h1>
          <Divider className='before-faq' />
          <Collapse items={getFaqItems()} bordered={false} expandIconPosition='end'></Collapse>

        </Sider>
      </Layout>
      <CustomFooter />
    </Layout>
  );
};

export default Proposal;

export const PROPOSAL_STATUS = {
  SUBMITTED: 'SUBMITTED',
  FILLED: 'FILLED',
  ACCEPTED: 'ACCEPTED',
  EXPIRED: 'EXPIRED',
};

export const FISCAL_MODEL = {
  TAKE_RATE: 'TAKE_RATE',
  FULL_SERVICE: 'FULL_SERVICE',
  SOFTWARE_LICENCE: 'SOFTWARE_LICENCE',
};

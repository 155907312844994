export const PERSONAL_INFO_TITLE = {
  pt: "Dados pessoais",
  es: "Información personal",
  en: "Personal Information",
};

export const CLIENT_NAME_LABEL = {
  pt: "Nome completo",
  es: "Nombre completo",
  en: "Full Name",
};

export const EMAIL_LABEL = {
  pt: "E-mail",
  es: "Correo electrónico",
  en: "Email",
};
  
export const CPF_LABEL = {
  pt: "CPF",
  es: "CPF",
  en: "CPF",
};

export const CLIENT_NAME_PLACEHOLDER = {
  pt: "Digite seu nome completo",
  es: "Ingrese su nombre completo",
  en: "Enter your full name",
};

export const EMAIL_PLACEHOLDER = {
  pt: "Digite seu e-mail",
  es: "Ingrese su correo electrónico",
  en: "Enter your email",
};

export const CPF_PLACEHOLDER = {
  pt: "Digite seu CPF",
  es: "Ingrese su CPF",
  en: "Enter your CPF",
};

export const INVALID_EMAIL = {
  pt: "Insira um email válido",
  es: "Ingrese un correo electrónico válido",
  en: "Enter a valid email",
};

export const INVALID_CPF = {
  pt: "Insira um CPF válido",
  es: "Ingrese un CPF válido",
  en: "Enter a valid CPF",
};

export const REQUIRED_FIELD = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Required field",
};
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Layout, Space, Checkbox, Button, notification } from 'antd';
import api from '../../services/api';
import Stepper from '../../components/Stepper';
import CustomFooter from '../../components/Footer';
import CustomHeader from '../../components/Header';
import ProposalTerms from './ProposalTerms';
import './styles.css';
import { PROPOSAL_STATUS } from '../../constants/proposal';

const { Content } = Layout;

const Confirmation = () => {
  const [proposal, setProposal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [checked, setChecked] = useState(false);
  const { id } = useParams();
  const history = useHistory();

  const fetchProposalData = async () => {
    try {
      const response = await api.get(`/proposals/${id}`);
      setProposal(response.data);
      setLoading(false);
    } catch (err) {
      setError('Proposta não encontrada');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProposalData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };

  const handleFinishClick = async () => {
    try {
      const updatedProposalResponse = await api.post(`/proposals/${id}/acceptance`);

      history.push({
        pathname: `/proposals/${id}/success`,
        state: { proposal: updatedProposalResponse.data }
      });

    } catch (error) {
      console.error('Erro ao aceitar proposta', error);
      notification.error({
        message: 'Erro ao aceitar proposta',
        description: 'Ocorreu um problema inesperado ao tentar aceitar a proposta. Por favor, tente novamente mais tarde ou entre em contato conosco.',
        duration: 12,
      })
    } 
  };  


  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (proposal.status === PROPOSAL_STATUS.ACCEPTED) {
    history.push({
      pathname: `/proposals/${id}/success`
    });
  }

  if (proposal.status === PROPOSAL_STATUS.EXPIRED) {
    history.push({
      pathname: `/proposals/${id}/expired`
    });
  }

  return (
    <div className='confirmation-page'>
      <Layout>
        <CustomHeader />
        <Content className='confirmation-content'>
          <Stepper current_step={1} />

          <h1>Leia e concorde com os termos da proposta</h1>
          <Space className='confirmation-space'>
            <ProposalTerms proposal={proposal} />
          </Space>

          <Space direction='vertical'>
            <Checkbox onChange={handleCheckboxChange}>
              Eu li e concordo com os Termos da proposta e com a Política de privacidade
            </Checkbox>
            <Button
              className='button'
              type="primary"
              onClick={handleFinishClick}
              disabled={!checked}
              style={{ marginTop: '20px' }}
            >
              Finalizar
            </Button>
          </Space>

        </Content>
        <CustomFooter />
      </Layout>
    </div>
  );
};

export default Confirmation;
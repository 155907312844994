import { Layout, Typography, Space } from 'antd';
import CustomFooter from '../../components/Footer';
import CustomHeader from '../../components/Header';
import * as texts from './locales';
import expiredImage from '../../assets/img/expired.png';

const { Content } = Layout;
const { Text } = Typography;

const contentStyle = {
  textAlign: 'left',
  padding: '16px 93px 16px 93px',
  backgroundColor: '#FFFFFF',
};

const Expired = () => {

  const lang = 'pt';

  return (
    <div className='success-page'>
      <Layout>
        <CustomHeader />
        <Content className='success-content' style={contentStyle}>
          <img src={expiredImage} alt='Expirado' className='success-image' /> 

          <Space className='success-text-space' direction='vertical'>
            <Text className='success-text-title'>{texts.EXPIRED_TITLE[lang]}</Text>
            <Text className='success-text-content'>{texts.EXPIRED_CONTENT[lang]}</Text>
          </Space>
          
        </Content>
        <CustomFooter />
      </Layout>
    </div>
  );
};

export default Expired;

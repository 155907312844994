export const SUCCESS_TITLE = {
  pt: "Obrigado por aceitar a proposta!",
  es: "¡Gracias por aceptar la propuesta!",
  en: "Thank you for accepting the proposal!",
};

export const SUCCESS_CONTENT_1 = {
  pt: "Estamos entusiasmados em tê-lo conosco na Tupi!",
  es: "¡Estamos emocionados de tenerte con nosotros en Tupi!",
  en: "We are excited to have you with us at Tupi!",
};

export const SUCCESS_CONTENT_2 = {
  pt: "Em breve você receberá um email com o termo de proposta aceito e orientações de próximos passos para conectar seus carregadores.",
  es: "Pronto recibirás un correo electrónico con el término de la propuesta aceptado y orientaciones sobre los próximos pasos para conectar tus cargadores.",
  en: "You will soon receive an email with the accepted proposal terms and guidance on the next steps to connect your chargers.",
};

export const SUCCESS_CONTENT_3 = {
  pt: "Se tiver alguma dúvida, entre em contato com o gerente comercial que te acompanhou durante este processo.",
  es: "Si tienes alguna duda, contacta al gerente comercial que te acompañó durante este proceso.",
  en: "If you have any questions, please contact the sales manager who accompanied you during this process.",
};

export const DOWNLOAD_TERMS = {
  pt: "BAIXAR TERMO DA PROPOSTA",
  es: "DESCARGAR TÉRMINOS DE LA PROPUESTA",
  en: "DOWNLOAD PROPOSAL TERMS",
};

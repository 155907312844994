export const COMPANY_INFO_TITLE = {
  pt: "Dados da empresa",
  es: "Información de la empresa",
  en: "Company Information",
};

export const COMPANY_NAME_LABEL = {
  pt: "Nome da empresa",
  es: "Nombre de la empresa",
  en: "Company Name",
};

export const CNPJ_LABEL = {
  pt: "CNPJ",
  es: "CNPJ",
  en: "CNPJ",
};

export const MUNICIPAL_REGISTRATION_LABEL = {
  pt: "Inscrição municipal",
  es: "Registro municipal",
  en: "Municipal Registration",
};

export const STATE_REGISTRATION_LABEL = {
  pt: "Inscrição estadual",
  es: "Registro estatal",
  en: "State Registration",
};

export const ZIP_CODE_LABEL = {
  pt: "CEP",
  es: "Código postal",
  en: "ZIP Code",
};

export const ADDRESS_LABEL = {
  pt: "Endereço",
  es: "Dirección",
  en: "Address",
};

export const ADDRESS_COMPLEMENT_LABEL = {
  pt: "Complemento",
  es: "Complemento",
  en: "Complement",
};

export const COMPANY_NAME_PLACEHOLDER = {
  pt: "Digite o nome da empresa",
  es: "Ingrese el nombre de la empresa",
  en: "Enter the company name",
};

export const CNPJ_PLACEHOLDER = {
  pt: "Digite o CNPJ da empresa",
  es: "Ingrese el CNPJ de la empresa",
  en: "Enter the company's CNPJ",
};

export const MUNICIPAL_REGISTRATION_PLACEHOLDER = {
  pt: "Digite a inscrição municipal da empresa (opcional)",
  es: "Ingrese el registro municipal de la empresa (opcional)",
  en: "Enter the company's municipal registration (optional)",
};

export const STATE_REGISTRATION_PLACEHOLDER = {
  pt: "Digite a inscrição estadual da empresa (opcional)",
  es: "Ingrese el registro estatal de la empresa (opcional)",
  en: "Enter the company's state registration (optional)",
};

export const ZIP_CODE_PLACEHOLDER = {
  pt: "Digite o CEP da empresa",
  es: "Ingrese el código postal de la empresa",
  en: "Enter the company's ZIP code",
};

export const ADDRESS_PLACEHOLDER = {
  pt: "Digite o endereço da empresa",
  es: "Ingrese la dirección de la empresa",
  en: "Enter the company's address",
};

export const ADDRESS_COMPLEMENT_PLACEHOLDER = {
  pt: "Digite o complemento do endereço (opcional)",
  es: "Ingrese el complemento de la dirección (opcional)",
  en: "Enter the address complement (optional)",
};

export const INVALID_CNPJ = {
  pt: "Insira um CNPJ válido",
  es: "Ingrese un CNPJ válido",
  en: "Enter a valid CNPJ",
};

export const INVALID_NUMBER = {
  pt: "Insira um número válido",
  es: "Ingrese un número válido",
  en: "Enter a valid number",
};

export const INVALID_ZIP_CODE = {
  pt: "Insira um CEP válido",
  es: "Ingrese un código postal válido",
  en: "Enter a valid ZIP code",
};

export const REQUIRED_FIELD = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Required field",
};
import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Proposal from './pages/Proposal';
import Confirmation from './pages/Confirmation';
import Success from './pages/Success';
import Expired from './pages/Expired';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/proposals/:id" exact component={Proposal} />
        <Route path="/proposals/:id/confirmation" component={Confirmation} />
        <Route path="/proposals/:id/success" component={Success} />
        <Route path="/proposals/:id/expired" component={Expired} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;

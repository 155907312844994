import React from 'react';
import { Steps } from 'antd';
import './styles.css';

const { Step } = Steps;

const steps = [
  {
    title: 'Informações do cliente',
  },
  {
    title: 'Termos da Proposta',
  },
];

const CustomStepper = ( {current_step} ) => {

  return (
    <div>
      <Steps
        className='custom-steps'
        size="small"
        current={current_step}
      >
        {steps.map((step, index) => (
          <Step key={index} title={step.title} />
        ))}
      </Steps>
      
    </div>
  );
};

export default CustomStepper;

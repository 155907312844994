import React from 'react';
import { Form, Select, InputNumber, Typography } from 'antd';
import * as texts from './locales';

const { Text } = Typography;

const getBrandOptions = (listModels) => {
  return listModels.map((model) => ({
    label: model.name,
    value: model.name,
  }));
};

const ChargersInfo = ( {listModels} ) => {
  const lang = 'pt';

  return (
    <>
      <Text className='charger-info-title'>{texts.CHARGER_INFO_TITLE[lang]}</Text>
      <Form.Item
        className='form-field' 
        label={texts.CHARGER_BRAND_LABEL[lang]}
        name="chargerBrands"
        rules={[{ required: true, message: texts.INVALID_CHARGER_BRAND[lang] }]}
      >
        <Select
          mode='multiple'
          allowClear
          showSearch
          placeholder={texts.CHARGER_BRAND_PLACEHOLDER[lang]}
          optionFilterProp='label'
          options={getBrandOptions(listModels)}
        />
      </Form.Item>

      <Form.Item
        className='form-field' 
        label={texts.CHARGER_QUANTITY_LABEL[lang]}
        name="chargerQuantity"
        rules={[{ required: true, message: texts.REQUIRED_FIELD[lang] }]}
      >
        <InputNumber
          style={{ width: "100%" }}
          placeholder={texts.CHARGER_QUANTITY_PLACEHOLDER[lang]}
          max={999}
        />
      </Form.Item>
    </>
  );
};

export default ChargersInfo;

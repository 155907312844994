import { Layout, Typography, Divider } from 'antd';
import './styles.css';
import logo from '../../assets/img/logo.png';

const { Footer } = Layout;
const { Text, Link } = Typography;

const CustomFooter = () => {
  return (
    <div className='footer'>
      <Footer className="upper-footer">
        <img src={logo} alt='Logo Tupi Mobilidade' className='footer-logo' />
        <Divider className="vertical-divider" type="vertical" />
        <Text className='slogan-text'>Venha construir o futuro da mobilidade</Text>
      </Footer>

      <Footer className="lower-footer">
        <Text className='white-text'>© 2024 Tupi Energia • </Text>
        <Link href='https://tupimob.com/politica-de-privacidade/'>
          <Text underline className='link-text'>Política de Privacidade</Text>
        </Link>
        <Text className='white-text'> • </Text>
        <Link href='https://tupimob.com/termos-de-uso/'>
          <Text underline className='link-text'>Termos de Uso</Text>
        </Link>
      </Footer>
    </div>
  );
};

export default CustomFooter;

